label{
    color: white;
}
h5{
    color: white;
}
div#contact.contact-section.container-fluid {
    background-color: #110e1b;
}
.errorMessage {
    color: #fff;
}
input#resume.is-invalid-input {
    color: #fff;
}