/* --------- */
/*Scrollbar   */
/* --------- */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #181C25;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #3E465D;
    border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6C789D;
    border-radius: 12px;
}

/* --------- */
/*Navbar   */
/* --------- */
nav.navbar.navbar-expand-sm.navbar-dark.bg-dark {
    background-color: #242424;
    height: 155px;
}
.navbar-nav {
    margin-left: 15px;
}

div#responsive-navbar-nav.navbar-collapse.collapse {
    justify-content: end;
}

nav.navbar.navbar-expand-md.navbar-light.fixed-top {
    position: absolute;
}

a.nav-link {
    color: #f5b51b !important;
    font-weight: 660;
    text-align: center;
}

nav.navbar.navbar-expand-md.navbar-light.fixed-top {
    background-color: #242424;
    height: 140px;
}
.nav-link:focus,
.nav-link:hover {
    color: white !important;
}
nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
    background-color: #1f1d1d!important;
}
.navbar-light .navbar-toggler { 
    border-color: #f5b51b;
    color: #28a745;
}
img.img-fluid {
    height: 180px;
    width: 180px;
    margin-left: 3px;
}
/* --------- */
/*Hero Container   */
/* --------- */
.hero-container {
    background-image: url("./Assets/Images/henning-witzel-ukvgqriuOgo-unsplash(1).jpg");
    background-color: #110e1b;
    background-blend-mode: screen;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}

.hero-container>h1 {
    color: #fff;
    margin-top: -525px;
    text-shadow: 3px 13px 13px #1f1d1d;
}

.hero-container>p {
    margin-top: 8px;
    color: #1f1d1d;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
}

h1.headline {
    font-size: 100px;
    font-weight: 800;
}
button.btn.btn--primary.btn--large {
    background-color: #181C25;
    color: #f5B51B;
    --bs-btn-hover-border-color: #fff;
    width: 175px;
    margin-top: 25px;
}
a.btn-mobile {
    width: 265px;

}
button.navbar-toggler {
    background-color: #f5B51B;
}
.Typewriter__wrapper {
    font-size: 1.6em;
    color: #fff !important;
    font-weight: 600 !important;
}

.Typewriter__cursor {
    font-size: 1.9em;
    color: #1f1d1d !important;
}

div.Typewriter {
    max-width: 910px;

}
div.progressQuotes {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-height: 50px;
    margin-top: 148px;
    text-shadow: 1px 8px 8px #000;
}

/* --------- */
/*About   */
/* --------- */
.about-container.container-fluid {
    height: 713px;
    padding-top: 15px;
}
div.about-description.row{
    text-align: center;
}

div.about-container.container-fluid {
    background-color: #110e1b;
}
/* --------- */
/*Mision   */
/* --------- */
.mission-container.container-fluid {
    height: 713px;
    padding-top: 15px;
}

.mission-description.row {
    text-align: left;
}

img.missionImg-fluid {
    height: 278px;
    margin-top: -100px;
    padding-top: 30px;
}

.mission-img.row {
    margin-left: 515px;
}
div.mission-container.container-fluid {
    background-color: #110e1b;
}
/* --------- */
/*Contact   */
/* --------- */
#contact.contact-section.container-fluid {
    height: 713px;
    padding-top: 25px;
}

input#FormControlInputEmail.inputContactform-control{
    width: 420px;
}
input#FormControlInputSubject.inputContactform-control{
    width: 420px;
}

textarea#FormControlTextarea.inputContactform-control{
    width: 420px;
}
img.phone{
    width: 4%;
}
img.location{
    width: 4%;
}
img.email{
    width: 4%;
}
img.imgContact-fluid{
    width: 420px;
    height: 420px;
}
div.form-group1{
    margin-left: 20px;
    
}
div.form-group2{
    margin-left: 9px;

}
img.img-fluid-contact {
    height: 450px;
}
div.g-recaptcha {
    margin-left: 190px;
    padding-top: 5px;
}

/* --------- */
/*Footer   */
/* --------- */
.social-media-wrap {
    margin-top: 40px;
    margin-left: 380px;
}
.social-icons {
    width: 240px;
}

@media (min-width: 1921px) and (max-width: 2560px) {
    /* --------- */
    /*Hero Container   */
    /* --------- */
    .hero-container>h1 {
        margin-top: -660px;
    }
    div.progressQuotes {
    margin-top: 240px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 1073px;
        padding-top: 150px;
    }
    h1.about {
        font-size: 3rem;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 1073px;
        padding-top: 150px;
    }
    h1.mission {
        font-size: 3rem;
    }
    /* --------- */
    /*Services   */
    /* --------- */
    .cards {
        height: 1073px;
        padding-top: 100px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 1073px;
        padding-top: 150px;
    }
    h1.contactHeading {
        font-size: 3rem;
    }
    /* --------- */
    /*Footer   */
    /* --------- */
    a.social-logo {
        margin-right: 8px;
    }
}
/*  XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1201px) and (max-width: 1440px) {
   /* --------- */
    /*Hero Container   */
    /* --------- */
    .hero-container>h1 {
        margin-top: -458px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 694px;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 694px;
    }
    img.missionImg-fluid {
        height: 290px;
    }
    .mission-img.row {
        margin-left: 490px;
    }
    /* --------- */
    /*Services   */
    /* --------- */
    .cards {
        height: 1168px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 694px;
    }
    h5.contact {
        margin-bottom: 0;
    }
    hr.contact {
        margin: 0;
    }
    a.social-logo {
        margin-right: 8px;
    }
}
@media (min-width: 1281px) and (max-width: 1366px) {
    .hero-container.container{
        max-width: 1359px;
    }
    img.img-fluid.logo{
        margin-top: 340px;
    }
    h5.about{
        font-size: 1.15rem;
    }
    h4.about{
        font-size: .95rem;
    }
    h3.about{
        font-size: 1.65rem;
    }
}
/*  X-Large devices (large desktops, 1200px and up) */
@media (min-width: 993px) and (max-width: 1200px) {
    /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline{
        font-size: 76px;
        margin-top: -466px;
    }
    div.progressQuotes {
        margin-top: 80px;
        }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 594px;
    }
    h1.about {
        font-size: 2.5rem;
    }
    h2.about{
        font-size: 1.45rem;
    }
    h3.about{
        font-size: 1.25rem;
    }
    h4.about{
        font-size: 1rem;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 594px;
    }
    h1.mission {
        font-size: 2.5rem;
    }
    h2.mission{
        font-size: 1.45rem;
    }
    h3.mission{
        font-size: 1.25rem;
    }
    h4.mission{
        font-size: 1rem;
    }
    img.missionImg-fluid {
        height: 286px;
        margin-top: -60px;
    }
    .mission-img.row {
        margin-left: 416px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 594px;
    }
    h1.contactHeading {
        font-size: 2rem;
    }
    h5.contact {
        margin-bottom: 0;
    }
    hr.contact {
        margin: 0;
    }
    textarea.form-control {
        height: 70px;
    }
    /* --------- */
    /*Footer   */
    /* --------- */
    small.website-rights {
        margin-left: 8px;
    }
}
@media screen and (width <= 1024px) {
    /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline{
        font-size: 67px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 562px;
        padding-top: 15px;
    }
    br.aboutBR {
        display: none;
    }
     /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 562px;
        padding-top: 15px;
    }
    .mission-img.row {
        margin-left: 324px;
    }
    img.missionImg-fluid {
        height: 280px;
    }
    /* --------- */
    /*Services   */
    /* --------- */
    .cards {
        padding-top: 150px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 562px;
        padding-top: 25px;
    }
    img.img-fluid-contact {
        height: 416px;
    }
    /* --------- */
    /*Footer   */
    /* --------- */
    .social-media-wrap {
        margin-left: 215px;
    }
    small.website-rights {
        margin-left: 8px;
    }
    a.social-logo {
        width: 60px;
    }
}
/*  Large devices (desktops, 992px and up) */
@media  (min-width: 769px) and (max-width: 992px) {
    /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline{
        font-size: 65px;
        margin-top: -375px;
    }
    button.btn-btn--primary.btn--large {
        margin-top: 14px;
    }
    div.progressQuotes {
        margin-top: 35px;
        }
    .Typewriter__wrapper {
        font-size: 1.1rem;
    }
    div.Typewriter {
        max-width: 800px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 414px;

    }
    h1.about {
        font-size: 2rem;
        margin-bottom: 0;
    }
    h2.about{
        font-size: 1.40rem;
        margin-bottom: 0;
    }
    h3.about{
        font-size: 1.25rem;
        margin-bottom: 0;
    }
    h4.about{
        font-size: 1rem;
        margin-bottom: 0;
    }
    hr.about {
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .jobs.col {
        margin-top: -8px;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 414px;
    }
    h1.mission {
        font-size: 2rem;
        margin-bottom: 0;
    }
    h2.mission{
        font-size: 1.40rem;
        margin-bottom: 0;
    }
    h3.mission{
        font-size: 1.25rem;
        margin-bottom: 0;
    }
    h4.mission{
        font-size: 1rem;
        margin-bottom: 0;
    }
    img.missionImg-fluid {
        height: 230px;
        margin-top: -130px;
        padding-top: 88px;
    }
    .mission-img.row {
        margin-left: 385px;
    }
    /* --------- */
    /*Services   */
    /* --------- */
    .cards {
        padding-top: 15px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 414px;
        padding-top: 25px;
    }
    img.img-fluid-contact {
        height: 388px;
    }
    h1.contactHeading {
        font-size: 2rem;
        margin-bottom: 0;
    }
    h5.contact {
        font-size: .80rem;
        margin-bottom: 0;
    }
    div.row.uploadForm {
        height: 30px;
    }
    textarea.form-control.formInput {
        height: 60px;
    }
    .contact.col-md-7 {
        height: 383px;
    }
    .upload-button-wrapper {
        float: inline-start;
        width: 205px;
    }
    div.g-recaptcha {
        margin-left: 205px;
    }
    
    /* --------- */
    /*Footer   */
    /* --------- */
    .social-media-wrap {
        margin-left: 183px;
    }
    hr.contact {
        margin: 0;
    }
}
/*  Medium devices (tablets, 768px and up) */
@media  (min-width: 577px) and (max-width: 768px) { 
    /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline{
        font-size: 54px;
        margin-top: -528px;
    }
    div.progressQuotes {
        margin-top: 218px;
    }
    
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 818px;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 818px;
    }
    .mission-img.row {
        margin-left: 208px;
    }
    img.missionImg-fluid {
        height: 321px;
        margin-top: -119px;
        padding-top: 70px;
    }
     /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 818px;
        padding-top: 94px;
    }
    div.g-recaptcha {
        margin-left: 24px;
    }
    img.img-fluid-contact {
        height: 308px;
    }
    /* --------- */
    /*Footer   */
    /* --------- */
    .social-media-wrap {
        margin-left: 12px;
    }
    small.website-rights {
        margin-left: -10px;
    }
    .social-icons {
        width: 191px;
    }
}
@media (max-width: 900px) { 
    img.img-fluid.logo2{
        display: none;
    }
}
@media (max-width: 899px) { 
    img.img-fluid.logo1{
        display: none;
    }
    img.img-fluid.logo2{
        display:block;
    }
}
@media screen and (width <=767px) { 
     /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline{
        margin-top: -307px;
    }
    button.btn.btn--primary.btn--large { 
        margin-top: 7px;
    }
    div.progressQuotes {
        margin-top: 15px;
    }
    .Typewriter__wrapper {
        font-size: 1.1rem;
    }
    div.Typewriter {
        max-width: 638px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 951px;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 935px;
    }
    img.missionImg-fluid {
        height: 294px;
        margin-top: -111px;
        padding-top: 50px;
    }
    .mission-img.row {
        margin-left: 128px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 935px;
        padding-top: 25px;
    }
    .contact-img.col-md-5 {
        text-align: center;
    }

}
/* Small devices (landscape phones, 576px and up)  */
@media (max-width: 576px) { 
    /* --------- */
    /*Navbar   */
    /* --------- */
    .nav.container {
        height: 130px;
        margin-bottom: 104px;
    }
    a.nav-link {
        width: 525px;
    }
    .nav-item {
        width: 84px;
    }
    nav.navbar.navbar-expand-md.navbar-light.fixed-top {
        height: 200px;
    }
    /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline {
        font-size: 39px;
        margin-top: -304px;
    }
    div.Typewriter {
        max-width: 466px;
    }
    .Typewriter__wrapper {
        font-size: 1rem;
    }
    div.progressQuotes {
        margin-top: 8px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 922px;
    }
     /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 912px;
    }
    .mission-img.row {
        margin-left: 110px;
    }
     /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 1005px;
        padding-top: 25px;
    }
    img.img-fluid-contact {
        height: 381px;
        width: 381px;
    }
    div.contact-img.col-md-5 {
        width: 1px;
        margin-right: 383px;
    }
    /* --------- */
    /*Footer   */
    /* --------- */
    .social-media-wrap {
        display: block;
        margin-left: 7px;
    }
    .social-icons {
        display: block;
        width: 64px;
    }
    small.website-rights {
        font-size: .7rem;
    }
    a.nav-link {
        width: 545px;
    }
}
@media screen and (width <=560px) { 
    a.nav-link {
        width: 529px;
    }
}
@media screen and (width <=540px) { 
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 1199px;
    }
    a.nav-link {
        width: 509px;
    }
}
@media screen and (width <=535px) { 
    small.website-rights {
        font-size: .6rem;
    }
}
@media screen and (width <=529px) { 
    /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline {
        font-size: 36px;
    }
}
@media screen and (width <=527px) { 
    /* --------- */
    /*Hero Container   */
    /* --------- */
    
    div.Typewriter {
        max-width: 517px;
    }
    div.progressQuotes {
        margin-top: 15px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 953px;
        padding-top: 7px;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 722px;
        padding-top: 7px;
    }
    h1.mission {
        font-size: 2rem;
    }
    h2.mission{
        font-size: 1.45rem;
    }
    h3.mission{
        font-size: 1.25rem;
    }
    h4.mission{
        font-size: 1rem;
    }
    .mission-img.row {
        margin-left: 97px;
    }
    img.missionImg-fluid {
        height: 285px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 1067px;
        padding-top: 17px;
    }
    div.g-recaptcha{
        margin-left: 64px;
    }
    /* --------- */
    /*Footer   */
    /* --------- */

    .footer-logo {
        width: 41px;
        height: 41px;
    }
    .footer-container {
        height: 95px;
    }
    small.website-rights {
        font-size: .7rem;
    }
    .social-icons {
        width: 64px;
    }
}
@media screen and (width <=524px) { 
    a.nav-link {
        width: 493px;
    }
}
@media screen and (width <=508px) { 
    a.nav-link {
        width: 477px;
    }
}
@media screen and (width <=500px) {
    /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline {
        font-size: 34px;
    } 
    div.Typewriter {
        max-width: 390px;
    }
    .Typewriter__wrapper {
        font-size: .9rem;
    }
    div.progressQuotes {
        margin-top: 15px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 961px;
        padding-top: 17px;
    }
     /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 770px;
        padding-top: 17px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 1066px;
        padding-top: 17px;
    }
    div.g-recaptcha{
        margin-left: 50px;
    }
    /* --------- */
    /*Footer   */
    /* --------- */

    .footer-logo {
        width: 41px;
        width: 41px;
    }
    .footer-container {
        height: 95px;
    }
    small.website-rights {
        font-size: .6rem;
    }
    .social-icons {
        width: 64px;
    }
}
@media screen and (width <=492px) { 
    a.nav-link {
        width: 461px;
    }
}
@media screen and (width <=476px) { 
    a.nav-link {
        width: 445px;
    }
}
@media screen and (width <=468px) { 
    a.nav-link {
        width: 437px;
    }
}
@media screen and (width <=452px) { 
    a.nav-link {
        width: 421px;
    }
}
@media screen and (width <=451px) { 
    /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline {
        font-size: 30px;
    }
    div.Typewriter {
        max-width: 340px;
    }
    .Typewriter__wrapper {
        font-size: .8rem;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 721px;
        padding-top: 7px;
    }
    h1.about {
        font-size: 2rem;
        margin-bottom: 0;
    }
    h2.about{
        font-size: 1.45rem;
        margin-bottom: 0;
    }
    h3.about{
        font-size: 1.25rem;
        margin-bottom: 0;
    }
    h4.about{
        font-size: 1rem;
        margin-bottom: 0;
    }
     /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 798px;
        padding-top: 7px;
    }
    .mission-img.row {
        margin-left: 74px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 1066px;
        padding-top: 17px;
    }
    div.g-recaptcha{
        margin-left: 30px;
    }
    /* --------- */
    /*Footer   */
    /* --------- */

    .footer-logo {
        width: 41px;
        height: 41px;
    }
    .footer-container {
        height: 95px;
    }
    small.website-rights {
        font-size: .5rem;
    }
    .social-icons {
        width: 64px;
    }
}
@media screen and (width <=436px) { 
    a.nav-link {
        width: 405px;
    }
}
@media screen and (width <=432px) { 
    a.nav-link {
        width: 401px;
    }
}
@media screen and (width <=430px) {
    /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline {
        font-size: 30px;
        margin-top: -408px;
    }
    div.Typewriter {
        max-width: 320px;
    }
    div.progressQuotes {
        margin-top: 84px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 785px;
        padding-top: 30px;
    }
    h1.about {
        font-size: 2rem;
        margin-bottom: 0;
    }
    h2.about{
        font-size: 1.45rem;
        margin-bottom: 0;
    }
    h3.about{
        font-size: 1.25rem;
        margin-bottom: 0;
    }
    h4.about{
        font-size: 1rem;
        margin-bottom: 0;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 840px;
        padding-top: 30px;
    }
    h1.mission {
        font-size: 2rem;
    }
    h2.mission{
        font-size: 1.45rem;
    }
    h3.mission{
        font-size: 1.25rem;
    }
    h4.mission{
        font-size: 1rem;
    }
    .mission-img.row {
        margin-left: 49px;
    }
    /* --------- */
    /*Services   */
    /* --------- */
    .cards {
        padding-top: 30px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 1078px;
        padding-top: 30px;
    }
    div.g-recaptcha{
        margin-left: 28px;
    }
    /* --------- */
    /*Footer   */
    /* --------- */

    .footer-logo {
        width: 41px;
        height: 41px;
    }
    .footer-container {
        height: 95px;
    }
    small.website-rights {
        font-size: .4rem;
    }
    .social-icons {
        width: 64px;
    }
}
@media screen and (width <=420px) { 
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 830px;
    }
}
@media screen and (width <=416px) { 
    a.nav-link {
        width: 385px;
    }
}
@media screen and (width <=414px) {
     /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline {
        font-size: 27px;
        margin-top: -404px;
    }
    .Typewriter__wrapper {
        font-size: .7rem;
    }
    div.Typewriter {
        max-width: 304px;
    }
    div.progressQuotes {
        margin-top: 90px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 830px;
        padding-top: 31px;
    }
    h1.about {
        font-size: 2rem;
        margin-bottom: 0;
    }
    h2.about{
        font-size: 1.45rem;
        margin-bottom: 0;
    }
    h3.about{
        font-size: 1.25rem;
        margin-bottom: 0;
    }
    h4.about{
        font-size: 1rem;
        margin-bottom: 0;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 841px;
        padding-top: 31px;
    }
    h1.mission {
        font-size: 2rem;
    }
    h2.mission{
        font-size: 1.45rem;
    }
    h3.mission{
        font-size: 1.25rem;
    }
    h4.mission{
        font-size: 1rem;
    }
    .mission-img.row {
        margin-left: 50px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 1079px;
        padding-top: 31px;
    }
    div.g-recaptcha{
        margin-left: 15px;
    }
    /* --------- */
    /*Footer   */
    /* --------- */

    .footer-logo {
        width: 41px;
        height: 41px;
    }
    .footer-container {
        height: 95px;
    }
    small.website-rights {
        font-size: .4rem;
    }
    .social-icons {
        width: 64px;
    }
}
@media screen and (width <=407px) { 
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 862px;
    }
}
@media screen and (width <=400px) { 
    a.nav-link {
        width: 369px;
    }
}
@media screen and (width <=396px) { 
    a.nav-link {
        width: 346px;
    }
}
@media screen and (width <=398px) { 
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 855px;
    }
}
@media screen and (width <=379px) { 
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 879px;
    }
}
@media screen and (width <=368px) { 
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 898px;
    }
}
@media screen and (width <=365px) { 
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 917px;
    }
}
@media screen and (width <=361px) { 
    a.nav-link {
        width: 330px;
    }
}
@media screen and (width <=360px) {
     /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline {
        font-size: 24px;
        margin-top: -398px;
    }
    .Typewriter__wrapper {
        font-size: .7rem;
    }
   
    div.Typewriter {
        max-width: 250px;
    }
    div.progressQuotes {
        margin-top: 89px;
    }
    /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 900px;
        padding-top: 33px;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 939px;
        padding-top: 33px;
    }
    .mission-img.row {
        margin-left: 23px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 1066px;
        padding-top: 33px;
    }
    img.img-fluid-contact {
        height: 367px;
        width: 367px;
    }
    div.g-recaptcha{
        margin-left: -11px;
    }
    /* --------- */
    /*Footer   */
    /* --------- */

    .footer-logo {
        width: 41px;
        height: 41px;
    }
    .footer-container {
        height: 95px;
    }
    small.website-rights {
        font-size: .4rem;
    }
    .social-icons {
        width: 64px;
    }
}
@media screen and (width <=345px) { 
    a.nav-link {
        width: 314px;
    }
}
@media screen and (width <=328px) { 
    a.nav-link {
        width: 297px;
    }
}
@media screen and (width <=325px) { 
    a.nav-link {
        width: 295px;
    }
}
@media screen and (width <=320px) { 
    /* --------- */
    /*Hero Container   */
    /* --------- */
    h1.headline {
        font-size: 22px;
        margin-top: -430px;
    }
    .Typewriter__wrapper {
        font-size: .6rem;
    }
    div.progressQuotes {
        margin-top: 122px;
    }
     /* --------- */
    /*About   */
    /* --------- */
    .about-container.container-fluid {
        height: 801px;
        padding-top: 33px;
    }
    h1.about {
        font-size: 1.8rem;
        margin-bottom: 0;
    }
    h2.about{
        font-size: 1.25rem;
        margin-bottom: 0;
    }
    h3.about{
        font-size: 1.05rem;
        margin-bottom: 0;
    }
    h4.about{
        font-size: .8rem;
        margin-bottom: 0;
    }
    /* --------- */
    /*Mission   */
    /* --------- */
    .mission-container.container-fluid {
        height: 699px;
        padding-top: 33px;
    }
    .mission-img.row {
        margin-left: 10px;
    }
    h1.mission {
        font-size: 1.8rem;
    }
    h2.mission{
        font-size: 1.25rem;
    }
    h3.mission{
        font-size: 1.05rem;
    }
    h4.mission{
        font-size: .8rem;
    }
    .mission-img.row {
        margin-left: 6px;
    }
    /* --------- */
    /*Contact   */
    /* --------- */
    #contact.contact-section.container-fluid {
        height: 1072px;
        padding-top: 33px;
    }
    h5.contact {
        font-size: .80rem;
    }
    img.img-fluid-contact {
        height: 348px;
        width: 348px;
        margin-left: 3px;
        padding-left: 24px;
    }
    input#Resume.is-invalid-input {
        width: 240px;
    }
    div.g-recaptcha{
        margin-left: -28px;
    }
}